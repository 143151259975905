import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import ErrorMessage from "./ErrorMessage"
import SuccessMessage from "./SuccessMessage"

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalWrapper = styled.div`
  position: absolute;
  max-width: calc(100% - 3rem);
  aspect-ratio: 31.9/40.53;
  width: 29.4125rem;
  max-height: 40rem;
  background: #fff;
  text-align: center;
  left: 50%;
  top: 43%;
  transform: translate(-50%, calc(-50% + 3rem));

  border-radius: 9.75px;

  @media (min-width: 40em) {
    aspect-ratio: 51.53/38.18;
    width: 51.9rem;
    top: 50%;
  }

  @media (max-height: 680px) {
    aspect-ratio: 29.9 / 35.53;
  }
`

const CloseButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;

  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: rgba(16, 8, 189, 0.8);
  margin-bottom: 3rem;

  &:hover {
    color: var(--clr-secondary);
  }

  @media (max-height: 680px) {
    margin-bottom: 1rem;
  }
`

const Modal = ({ isOpen, closeModal, services = false, error }) => {
  const closeButtonRef = createRef()
  useEffect(() => {
    //Focus trap for modal according to improve accessibility.
    document.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.code === 9
      if (!isTabPressed || !isOpen) {
        return
      }
      e.preventDefault()
      if (closeButtonRef?.current) {
        closeButtonRef.current.focus()
      }
    })
  }, [closeButtonRef, isOpen])
  if (!isOpen) {
    return null
  }
  return (
    <ModalContainer onClick={closeModal}>
      <ModalWrapper onClick={e => e.stopPropagation()}>
        {!error ? <SuccessMessage services={services} /> : <ErrorMessage />}
        <CloseButton ref={closeButtonRef} onClick={closeModal}>
          Close
        </CloseButton>
      </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal
