import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const BackgroundImageContainer = styled.div`
  grid-area: image;
  grid-row: image / image2;
  grid-column: 1 / -1;
  margin-inline: auto;
  padding-top: 3.875rem;
  background: var(--clr-bg-grey);

  .gatsby-image-wrapper {
    min-width: 650px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -0%);
  }
`

const BackgroundImage = () => {
  return (
    <BackgroundImageContainer>
      <StaticImage
        src={`../../../images/common/apply_box_03.png`}
        alt=""
        quality={100}
        width={1139}
      />
    </BackgroundImageContainer>
  )
}

export default BackgroundImage
