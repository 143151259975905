/* eslint-disable prettier/prettier */
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Mask1 from "../../../images/landing-page/quotes/mask.svg"
import Mask2 from "../../../images/landing-page/quotes/mask_02.svg"
import Mask3 from "../../../images/landing-page/quotes/mask_03.svg"

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px,
    2px 2px 10px rgba(0, 0, 0, 0.15);
  text-align: center;

  margin: 0 auto;

  max-width: 275px;
  width: 100%;
  height: 325px;

  @media (min-width: 345px) {
    max-width: 310px;
  }

  @media (min-width: 360px) {
    max-width: 320px;
  }

  @media (min-width: 40em) {
    max-width: 420px;
    height: 390px;
  }
`
const QuoteContainer = styled.div`
  margin-top: 4rem;
  margin-left: 1.55rem;
  margin-right: 1.55rem;

  @media (min-width: 22.5em) {
    margin-top: 4.25rem;
    margin-left: 2.55rem;
  }

  @media (min-width: 40em) {
    margin-top: 4.25rem;
    margin-top: 5.375rem;
    margin-left: 3.25rem;
  }
`

const QuoteContent = styled.blockquote`
  color: #000000;
  quotes: "„" "“" "‚" "‘";
  position: relative;

  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.9em;
  line-height: 21px;
  letter-spacing: 0.0015em;

  text-align: left;

  @media (min-width: 40em) {
    max-width: 26ch;
    font-size: 1em;
    line-height: 24px;
  }
`

const ProfileContainer = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
`

const Profile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  margin-top: auto;
  margin-left: 1.55rem;
  margin-bottom: 0.875rem;

  max-width: 41%;

  @media (min-width: 22.5em) {
    margin-left: 2.55rem;
    margin-bottom: 1.875rem;
    max-width: 38%;
  }

  @media (min-width: 40em) {
    max-width: none;
    margin-bottom: 2rem;
    margin-left: 3.25rem;
    min-height: 60px;
  }

  @media (min-width: 64em) {
  }
`

const ProfileName = styled.div`
  color: var(--clr-text-blue);

  font-family: var(--ff-primary);
  font-size: var(--fs-16);
  font-weight: var(--fw-extrabold);
  font-weight: 700;
  font-size: 0.77em;
  line-height: 18px;
  letter-spacing: 0.0015em;

  @media (min-width: 40em) {
    font-size: 1em;
    line-height: 24px;
  }

  text-align: left;
`

const ProfileTitle = styled.p`
  color: var(--clr-text-blue);
  text-align: left;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 0.65em;
  line-height: 15px;
  letter-spacing: 0.0015em;

  @media (min-width: 40em) {
    font-weight: 700;
    font-size: 0.75em;
    line-height: 18px;
  }

  max-width: 20ch;
`

const ProfilePicture = styled(GatsbyImage)`
  filter: grayscale(1);
  border-width: ${props => props.index};
  mask-image: url(${props => props.index === 1 ? Mask1 : props.index === 2 ? Mask2 : Mask3});
  mask-size: contain;
  mask-position: ${props => (props.index !== 1 ? "0px 4px" : "0xp 0px")};
  img {
    object-position: 0px 4px;
  }

  min-height: 100px;
  min-width: 100px;
  height: 100px;
  width: 100px;

  position: absolute !important;
  bottom: -2rem;
  right: 1rem;

  @media (min-width: 21em) {
    min-height: 118px;
    min-width: 118px;
    height: 118px;
    width: 118px;
    bottom: -2rem;
    right: 1rem;
  }

  @media (min-width: 40em) {
    bottom: -3rem;
    right: 2rem;
    min-height: 150px;
    min-width: 150px;
    img {
      object-position: 0px 6px;
    }
    mask-position: ${props => (props.index !== 1 ? "0px 6px" : "0px 0px")};
  }
`

const LargeQuote = styled.div`
  display: none;
  @media (min-width: 40em) {
    display: block;
  }
  .largeQuote {
    position: absolute;
    top: -1rem;
    left: 3.25rem;
  }
`

const SmallQuote = styled.div`
  display: block;
  @media (min-width: 40em) {
    display: none;
  }

  .smallQuote {
    position: absolute;
    top: -0.8rem;
    left: 1.55rem;
    @media (min-width: 22.5em) {
      left: 2.55rem;
    }
  }
`

const Card = ({ quote, name, title, image, index }) => {
  return (
    <CardContainer>
      <LargeQuote>
        <StaticImage
          className="largeQuote"
          alt=""
          src="../../../images/landing-page/quote_large.png"
        />
      </LargeQuote>
      <SmallQuote>
        <StaticImage
          className="smallQuote"
          alt=""
          src="../../../images/landing-page/quote_small.png"
        />
      </SmallQuote>
      <QuoteContainer>
        <QuoteContent>{quote}</QuoteContent>
      </QuoteContainer>
      <ProfileContainer>
        <Profile>
          <ProfileName>{name}</ProfileName>
          <ProfileTitle>{title}</ProfileTitle>
        </Profile>
      </ProfileContainer>
      <ProfilePicture
        image={getImage(image)}
        alt={name}
        index={(index % 3) + 1}
      />
    </CardContainer>
  )
}

export default Card
