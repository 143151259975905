import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StoryBlockContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  overflow: clip;
  max-width: 580px;
  width: 100%;
  margin-inline: auto;
  cursor: pointer;

  border-bottom: 5px solid #b1fced;

  padding-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @media (min-width: 64em) {
    padding-top: 2rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  transition: 0.1s ease-in-out;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      /*border-bottom: 5px solid #71ead2;*/
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  z-index: 0;
  filter: grayscale(100%);
  image-rendering: high-quality;
  aspect-ratio: 343/173;
  @media (min-width: 64em) {
    aspect-ratio: 536/285;
  }
  img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const StoryInfoContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);

  @media (min-width: 64em) {
    margin-top: 1.75rem;
  }
`

const StoryTitle = styled.h2`
  position: relative;
  font-family: var(--ff-secondary);
  text-decoration: none;
  color: var(--clr-text-blue);
  font-weight: 700;
  font-size: 1.25em;
  line-height: 39px;
  letter-spacing: 0.0025em;
  text-align: left;

  @media (min-width: 22.5em) {
    font-size: 1.25em;
    line-height: 56px;
  }

  @media (min-width: 64em) {
    font-size: 1.5em;
    line-height: 62px;
  }
`

const StorySummaryText = styled.p`
  font-family: var(--ff-primary);
  height: fit-content;
  text-align: left;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;

  @media (min-width: 64em) {
    font-size: 16px;
    line-height: 24px;
  }
`

const SummaryAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 4.375rem;
  gap: 0.5rem;

  @media (min-width: 64em) {
    min-height: 5.375rem;
  }
`

const StoryDate = styled.div`
  margin-top: auto;
  color: var(--clr-text-blue);
  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.0015em;
  min-width: 44px;
  margin-bottom: 1rem;
  @media (min-width: 23.75em) {
    white-space: nowrap;
    min-width: auto;
  }

  @media (min-width: 40em) {
    font-size: 12px;
  }

  @media (min-width: 64em) {
    margin-bottom: 2.375rem;
  }
`

//className prop is needed for styled component extension to work i.e styled(StoryBlock)``
const StoryBlock = ({ story, isOdd, className }) => {
  let image = getImage(story.summaryImage)

  return (
    <StoryBlockContainer className={className} to={`/stories/${story.slug}`}>
      <StyledImage image={image} alt="post image" />
      <StoryInfoContainer className="textContent" isOdd={isOdd}>
        <StoryTitle to={"/stories/" + story.slug}>
          {story.summaryTitle}
        </StoryTitle>
        <SummaryAndDate className="summary">
          <StorySummaryText>"{story.summary}"</StorySummaryText>
          <StoryDate>
            {new Date(story.date).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </StoryDate>
        </SummaryAndDate>
      </StoryInfoContainer>
    </StoryBlockContainer>
  )
}

export default StoryBlock
