import React, { useState, useEffect, useRef } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Modal from "./Modal"
import StyledReCaptcha from "./StyledReCaptcha"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import BasicInput from "./inputs/BasicInput"
import TextAreaInput from "./inputs/TextAreaInput"
import FormButtonsContainer from "./buttons/ButtonsContainer"
import SubmitButton from "./buttons/SubmitButton"
import StyledForm from "./StyledForm"
import PrivacyLabel, { StyledLink } from "./PrivacyLabel"
import InputRow from "./InputRow"

const ApplySchema = Yup.object().shape({
  name: Yup.string().max(50, "Name is too long"),
  email: Yup.string()
    .email("Enter correct email")
    .required("Email is required"),
  company: Yup.string().max(100, "Link is too long"),
  message: Yup.string().max(4000, "Message is too long"),
  response: Yup.string().required("reCAPTCHA is required"),
})

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const ContactForm = ({ prefilled }) => {
  const [showModal, setShowModal] = useState(false)
  const [emailError, setEmailError] = useState()
  const { pathname } = useLocation()
  const formikRef = useRef()
  let keepValues

  useEffect(() => {
    if (formikRef.current || prefilled) {
      formikRef.current.setFieldValue("message", prefilled)
    }
  }, [prefilled])

  const toggleModal = () => {
    // if on open-positions closing modal returns to previous page i.e the article
    if (pathname.includes("/open-positions/") && showModal && !emailError) {
      navigate(`/open-positions/${pathname.split("/")[2]}`)
    }
    setShowModal(!showModal)
  }

  const sendMail = async values => {
    try {
      const response = await fetch(
        process.env.GATSBY_EMAIL_LAMBDA + "contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      )
      const responseBody = await response.json()
      if (!response.ok) {
        throw new Error("Failed to submit form", { cause: responseBody })
      }
      setEmailError(false)
      keepValues = false
    } catch (err) {
      console.error(err.message)
      setEmailError(true)
      keepValues = true
      try {
        await fetch("https://api.rebase.fi/dev/error-notification/slack", {
          method: "POST",
          body: JSON.stringify({
            service: "Contact Form",
            message: err.message,
            error: err.cause,
          }),
        })
      } catch {
        console.error("Failed to post Errro notification")
      }
    }
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: "",
          email: "",
          company: "",
          message: "",
          response: "",
        }}
        validationSchema={ApplySchema}
        onSubmit={async (values, { resetForm }) => {
          await sendMail(values)
          if (isBrowser) {
            window.grecaptcha.reset()
          }
          if (keepValues === false) {
            resetForm({ values: "" })
          }
          toggleModal()
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <StyledForm>
            <InputRow>
              <BasicInput
                label="Email*"
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Enter your email"
              />
              <BasicInput
                label="Name"
                name="name"
                autoComplete="name"
                type="text"
                placeholder="Enter your name"
              />
            </InputRow>
            <BasicInput
              label="Company"
              name="company"
              type="text"
              placeholder="Enter your company's name"
            />
            <TextAreaInput
              label="Your message"
              name="message"
              placeholder="Enter your message"
              rows="3"
            />
            <PrivacyLabel>
              I consent to Rebase storing and using this information to contact
              me in relation to starting a new project account (see our{" "}
              <StyledLink to="/privacy-notice">privacy notice</StyledLink> for
              more information).
            </PrivacyLabel>
            <StyledReCaptcha
              onChange={response => {
                setFieldValue("response", response)
              }}
              onErrored={e => console.error(e)}
            />
            <FormButtonsContainer>
              <SubmitButton label="send" isSubmitting={isSubmitting} />
            </FormButtonsContainer>
          </StyledForm>
        )}
      </Formik>
      <Modal
        isOpen={showModal}
        error={emailError}
        closeModal={toggleModal}
        services={true}
      />
    </>
  )
}

export default ContactForm
