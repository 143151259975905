import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: left;

  height: 400px;

  @media (min-width: 40em) {
    width: 450px;
  }
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 265px;
  margin-top: 1rem;
`

const Title = styled.div`
  font-family: var(--ff-primary);
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  color: black;
  text-align: center;
`

const Subtitle = styled.div`
  font-family: var(--ff-primary);
  font-size: 22px;
  font-weight: 300;
  color: black;
  text-align: center;
`

const ImageContainer = styled.div`
  width: 200px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 2rem;
`

const CertificationTextBottom = () => {
  return (
    <Container>
      <ImageContainer>
        <StaticImage
          src="../../images/common/asiakastieto_logo.png"
          alt="Company Logo"
          placeholder="blurred"
          layout="fixed"
          width={200}
          height={220}
        />
      </ImageContainer>
      <TextContainer>
        <Title>STRONGEST IN FINLAND PLATINUM</Title>
        <Subtitle>Rebase Consulting Oy</Subtitle>
        <Subtitle>FI31250352 | 2021-2024</Subtitle>
      </TextContainer>
    </Container>
  )
}

export default CertificationTextBottom
