import * as React from "react"
import styled from "styled-components"

const CommonBoxSection = styled.section`
  display: flex;
  flex-direction: column;

  height: 160px;
  width: 170px;
  padding: 1rem;

  background-color: white;
  color: black;
  box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px,
    2px 2px 10px rgba(0, 0, 0, 0.15);

  align-items: center;
  justify-content: center;

  @media (min-width: 52em) {
    height: 208px;
    width: 225px;
    padding: 1rem 2rem;
  }

  @media (min-width: 64em) {
    height: 266px;
    width: 288px;
  }
`

const Content = styled.div`
  text-align: center;
`

const Number = styled.strong`
  font-family: var(--ff-secondary);
  font-size: 2.125em;
  font-weight: 800;
  text-align: center;
  color: var(--clr-text-blue);
  line-height: 44px;

  @media (min-width: 64em) {
    font-size: 5.5em;
    line-height: 130px;
  }
`

const Extra = styled.strong`
  font-family: var(--ff-secondary);
  font-size: 1.4em;
  font-weight: var(--fw-extrabold);
  text-align: center;
  color: var(--clr-text-blue);

  @media (min-width: 64em) {
    font-size: 3.5em;
  }
`

const ExtraFront = styled(Extra)`
  font-size: 1.8em;
  @media (min-width: 64em) {
    font-size: 4.5em;
  }
`

const ParagraphContent = styled.div`
  text-align: center;
`

const Paragraph = styled.p`
  font-family: var(--ff-secondary);

  font-weight: 600;
  font-size: 0.85em;
  line-height: 24px;
  margin-top: 0.5rem;

  color: #000000;
  text-align: center;

  @media (min-width: 64em) {
    font-weight: 700;
    font-size: 1.375em;
    line-height: 34px;
    min-height: 78px;
    margin-top: 0;
  }
`

const CommonBox = ({ number, extra, extraFront, content }) => {
  return (
    <CommonBoxSection>
      <Content>
        {extraFront && <ExtraFront>{extraFront}</ExtraFront>}
        <Number>{number}</Number>
        {extra && <Extra>{extra}</Extra>}
      </Content>
      <ParagraphContent>
        <Paragraph>{content}</Paragraph>
      </ParagraphContent>
    </CommonBoxSection>
  )
}

export default CommonBox
