import { Link } from "gatsby"
import styled from "styled-components"

export const DiscoverLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  color: ${props => (props.color ? props.color : "white")};
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 0.875em;
  line-height: 27px;
  letter-spacing: 0.0015em;

  width: fit-content;

  @media (min-width: 40em) {
    font-size: 1.125em;
    gap: 0.3375rem;
  }

  .arrow-icon {
    margin-bottom: -3px;
    margin-left: -3px;
    position: relative;
    top: -1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    vertical-align: middle;
  }

  .arrow-icon--circle {
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 101;
    stroke-dashoffset: 101;
  }

  &:hover {
    .arrow-icon {
      transform: translate3d(5px, 0, 0);
    }

    .arrow-icon--circle {
      stroke-dashoffset: 0;
    }
  }
`
