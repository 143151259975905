import styled from "styled-components"

const SecondaryTitle = styled.h2`
  width: min(1000px, 100% - 2.5rem);
  margin-inline: auto;
  color: var(--clr-text-blue);
  text-align: center;
  margin-bottom: 2rem;

  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 1.5em;
  line-height: 39px;

  @media (min-width: 40em) {
    margin-bottom: 4rem;
    font-size: 2.1875em;
    line-height: 56px;
    letter-spacing: 0.0025em;
  }
`

export default SecondaryTitle
