import React, { createRef, useState } from "react"
import styled from "styled-components"
import rebaseVideo from "../../../video/rebase_video.mp4"
import { getSrc } from "gatsby-plugin-image"
import Play from "../../../assets/svgs/play_button.svg"

const VideoSection = styled.section`
  margin-inline: auto;
  position: relative;
  margin-bottom: 5.5rem;
  width: min(1000px, 100% - 2.5rem);

  @media (min-width: 40em) {
    margin-bottom: 7.5rem;
  }

  &:hover {
    .play {
      fill-opacity: 0.8;
    }
  }
`

const StyledVideo = styled.video`
  width: 100%;
  border-radius: 0px;
  cursor: pointer;
  display: block;
`

const PlayButton = styled(Play)`
  fill-opacity: 0.6;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${props => (props.playing ? "none" : "block")};

  @media (max-width: 40em) {
    height: 90px;
  }
`

const Video = ({ poster }) => {
  const videoRef = createRef()
  const [playing, setPlaying] = useState(false)

  const onClick = () => {
    console.log(videoRef.current)
    if (videoRef.current.paused) {
      videoRef.current.play()
      setPlaying(true)
    } else {
      videoRef.current.pause()
      setPlaying(false)
    }
  }

  const onEnded = () => {
    setPlaying(false)
  }

  return (
    <VideoSection>
      <PlayButton className="play" playing={playing} />
      <StyledVideo
        ref={videoRef}
        muted={true}
        poster={getSrc(poster)}
        controls={false}
        onClick={onClick}
        onEnded={onEnded}
      >
        <source src={rebaseVideo} type="video/mp4" />
      </StyledVideo>
    </VideoSection>
  )
}

export default Video
