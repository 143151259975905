import React from "react"
import styled from "styled-components"
import MainTitle from "./MainTitle"
import MainParagraph from "./MainParagraph"

const PageIntroSection = styled.section`
  margin-inline: auto;
  width: 100%;
  max-width: ${props => `min(${props.maxWidth}, 100% - 2.5rem)`};
`

const PageIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 55em) {
    margin-inline: ${props => (props.center ? "auto" : "unset")};
    text-align: ${props => (props.center ? "center" : "left")};
  }
`

const PageIntro = ({ title, subheading, center, maxWidth = "79ch" }) => {
  return (
    <PageIntroSection maxWidth={maxWidth}>
      <PageIntroContainer center={center}>
        <MainTitle title={title} />
        <MainParagraph>{subheading}</MainParagraph>
      </PageIntroContainer>
    </PageIntroSection>
  )
}

export default PageIntro
