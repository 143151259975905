import React, { useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "../../../styles/carousel/carousel.css"
import Chevron from "../../../assets/svgs/chevron.svg"
import styled from "styled-components"
import { Pagination, Navigation } from "swiper"
import Card from "./Card"
import { ButtonNext, ButtonPrevious } from "../../common/CarouselButtons"
import SecondaryTitle from "../../common/SecondaryTitle"

export const CarouselTitle = styled.h2`
  color: var(--clr-text-blue);
  width: calc(100% - 2rem);
  margin-inline: auto;
  text-align: center;
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 1.5em;
  line-height: 39px;

  margin-bottom: 2rem;

  @media (min-width: 40em) {
    font-size: 2.125em;
    line-height: 56px;
    letter-spacing: 0.0025em;
    margin-bottom: 5rem;
  }
`

const CarouselContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: min(338px, calc(100% - 2rem));
  margin-inline: auto;
  position: relative;
  margin-bottom: 5.5rem;

  @media (min-width: 64em) {
    display: flex;
    margin-bottom: 7.5rem;
    width: min(883px, calc(100% - 2rem));
  }

  @media (min-width: 1320px) {
    width: min(1332px, calc(100% - 2rem));
  }
`

const MobileCarousel = styled.div`
  margin-bottom: 4.5rem;
  @media (min-width: 64em) {
    display: none;
  }
`

const LineBreak = styled.br`
  @media (min-width: 40em) {
    display: none;
  }
`

const Carousel = ({ data }) => {
  const prevRef = React.useRef()
  const nextRef = React.useRef()
  const [swiper, setSwiper] = React.useState()

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  return (
    <>
      <SecondaryTitle>
        Hear What People Say <LineBreak /> About Us
      </SecondaryTitle>

      <CarouselContainer>
        <ButtonPrevious ref={prevRef} aria-label="previous">
          <Chevron />
        </ButtonPrevious>
        <Swiper
          pagination={true}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          slidesPerView="auto"
          spaceBetween={28}
          breakpoints={{
            900: {
              slidesPerView: 2,
            },
            1320: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Navigation]}
          onSwiper={setSwiper}
          loop={true}
        >
          {data.quotes.nodes?.map((quote, index) => (
            <SwiperSlide key={quote.id}>
              <Card
                quote={quote.quote}
                name={quote.name}
                title={quote.title}
                image={quote.image}
                index={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <ButtonNext ref={nextRef} aria-label="next">
          <Chevron />
        </ButtonNext>
      </CarouselContainer>

      <MobileCarousel>
        <Swiper
          pagination={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          breakpoints={{
            385: {
              spaceBetween: 16,
            },
          }}
          loop={true}
          className="mySwiper"
          modules={[Pagination]}
        >
          {data.quotes.nodes?.map((quote, index) => (
            <SwiperSlide className="swiper-slide-mobile" key={quote.id}>
              <Card
                quote={quote.quote}
                name={quote.name}
                title={quote.title}
                image={quote.image}
                index={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileCarousel>
    </>
  )
}

export default Carousel
