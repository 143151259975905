import React from "react"
import styled from "styled-components"
import CommonBox from "./InfoBox"

export const InfoBoxesContainer = styled.div`
  margin-inline: auto;
  position: relative;
  padding-top: 5.125rem;
  padding-bottom: 5.125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: var(--clr-secondary-light);
  @media (min-width: 40em) {
    padding-top: 6.4375rem;
    padding-bottom: 6.4375rem;
  }
`

const EvenBoxes = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-around;
  max-width: 1000px;
  margin-inline: auto;

  @media (min-width: 40em) {
    flex-direction: row;
  }
`

const InfoBoxes = ({ content }) => {
  return (
    <InfoBoxesContainer>
      <EvenBoxes>
        {content.map((box, index) => (
          <CommonBox
            key={box.number + index}
            number={box.number}
            extra={box.extra}
            extraFront={box.extraFront}
            content={box.content}
          />
        ))}
      </EvenBoxes>
    </InfoBoxesContainer>
  )
}

export default InfoBoxes
