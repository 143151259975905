import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ApplyButtonContainer = styled.section`
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;

  margin-left: 1.25rem;
  margin-right: 1.25rem;

  @media (min-width: 40em) {
    text-align: center;
    margin-bottom: 7.5rem;
    margin-top: 1.75rem;
  }
`

const StyledApplyLink = styled(Link)`
  display: inline-block;
  border: 0;
  border-radius: 0px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.0046em;
  color: #f5f5f5;

  width: 200px;
  line-height: 49px;

  background-color: var(--clr-primary);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  position: relative;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--clr-tertiary-bright);
    color: var(--clr-text-blue);
  }
`

const ApplyButton = ({ className }) => {
  return (
    <ApplyButtonContainer>
      <StyledApplyLink className={className} to="#contact" title="Apply">
        Contact us
      </StyledApplyLink>
    </ApplyButtonContainer>
  )
}

export default ApplyButton
