import styled from "styled-components"

const Main = styled.main`
  padding-top: 6.875rem;

  @media (min-width: 40em) {
    padding-top: 5.5rem;
  }

  @media (min-width: 64em) {
    padding-top: 5.5rem;
  }
`

export default Main
