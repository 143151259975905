import styled from "styled-components"

const FormBoxContainer = styled.article`
  display: grid;
  grid-template-areas:
    "image"
    "applybox"
    "image2";
  justify-items: center;

  gap: 2rem;
  padding-bottom: 3rem;

  background: var(--clr-bg-grey);
  z-index: -1;

  @media (min-width: 40em) {
    grid-template-areas:
      "image"
      "image2"
      "applybox";
    gap: 0;
  }
`

export default FormBoxContainer
