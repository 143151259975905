import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import SecondaryTitle from "../../common/SecondaryTitle"
import { DiscoverLink } from "./DiscoverLink"

const OfferBoxSection = styled.section`
  margin-bottom: 5.5rem;
  @media (min-width: 40em) {
    margin-bottom: 7.5rem;
  }
`

const OfferBoxesContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin-inline: auto;

  @media (min-width: 40em) {
    gap: 2rem;
  }

  @media (min-width: 64em) {
    gap: 3rem;
  }
`

const OfferBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => (props.reverse ? "column-reverse" : "column ")};

  padding-top: 3rem;
  padding-bottom: 3.25rem;
  padding-right: 3.5625rem;
  padding-left: 3.5625rem;

  background-color: ${props => props.color};
  gap: 3rem;

  @media (min-width: 40em) {
    flex-direction: row;
    padding-top: 2.32rem;
    padding-bottom: 2.32rem;
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .image {
    max-width: 148px;
    @media (min-width: 40em) {
      min-width: 218px;
      max-width: none;
    }
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 17.5rem;
`

const BoxTitle = styled.h3`
  color: white;
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 800;
  font-size: 1.25em;
  line-height: 33px;

  margin-bottom: 1rem;

  @media (min-width: 40em) {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 39px;
  }
`

const BoxDescription = styled.p`
  color: white;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  letter-spacing: 0.0015em;

  margin-bottom: 1.16rem;

  @media (min-width: 40em) {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 27px;
    margin-bottom: 1.47rem;
  }
`

const LineBreak = styled.br`
  @media (min-width: 40em) {
    display: none;
  }
`

const OfferBoxes = () => {
  return (
    <OfferBoxSection>
      <SecondaryTitle>
        Your Base for <LineBreak /> Growth and Talent
      </SecondaryTitle>
      <OfferBoxesContainer>
        <OfferBox color="var(--clr-primary)" reverse={true}>
          <TextContent>
            <BoxTitle>FOR OUR PEOPLE</BoxTitle>
            <BoxDescription>
              Rebase = freedom, simplicity, great work & great compensation—in
              one unique package. Join us and work with the best in the
              business.
            </BoxDescription>
            <DiscoverLink to="/careers">
              Learn more
              <svg
                className="arrow-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
              >
                <g
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                >
                  <circle
                    className="arrow-icon--circle"
                    cx="17"
                    cy="17"
                    r="16.12"
                  ></circle>
                  <path
                    stroke="none"
                    className="arrow-icon--arrow"
                    d="M14.7949 6.5L12.3274 8.9675L20.3424 17L12.3274 25.0325L14.7949 27.5L25.2949 17L14.7949 6.5Z"
                    fill="white"
                  ></path>
                </g>
              </svg>{" "}
              <br />
            </DiscoverLink>
          </TextContent>
          <StaticImage
            className="image"
            src="../../../images/landing-page/offerbox_01.png"
            quality={80}
            style={{ maxWidth: "372px" }}
            alt=""
          />
        </OfferBox>
        <OfferBox color="var(--clr-teritiary-soft-violet-dark)">
          <StaticImage
            className="image"
            src="../../../images/landing-page/offerbox_02.png"
            quality={80}
            style={{ maxWidth: "398px" }}
            alt=""
          />
          <TextContent>
            <BoxTitle>FOR OUR CLIENTS</BoxTitle>
            <BoxDescription>
              You get experts with entrepreneurial spirit across software,
              cloud, agile and design who are ready to help you grow.
            </BoxDescription>
            <DiscoverLink to="/services">
              Learn more
              <svg
                className="arrow-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
              >
                <g
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                >
                  <circle
                    className="arrow-icon--circle"
                    cx="17"
                    cy="17"
                    r="16.12"
                  ></circle>
                  <path
                    stroke="none"
                    className="arrow-icon--arrow"
                    d="M14.7949 6.5L12.3274 8.9675L20.3424 17L12.3274 25.0325L14.7949 27.5L25.2949 17L14.7949 6.5Z"
                    fill="white"
                  ></path>
                </g>
              </svg>{" "}
              <br />
            </DiscoverLink>
          </TextContent>
        </OfferBox>
      </OfferBoxesContainer>
    </OfferBoxSection>
  )
}

export default OfferBoxes
