import styled from "styled-components"

const NavButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffff;
  border: 0;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%),
    rgb(122 121 121 / 20%) 0px 0px 0px 1px;
  width: 40px;
  height: 40px;
  border-radius: 100%;

  @media (min-width: 40em) {
    width: 50px;
    height: 50px;
  }
`

export const ButtonPrevious = styled(NavButton)`
  position: absolute;
  left: -11px;
  z-index: 99;
  top: calc(50% - 3rem);

  @media (min-width: 40em) {
    left: -17px;
    top: calc(50% - 4.5rem);
  }

  svg {
    filter: brightness(0%);
    transform: rotate(90deg);
    width: 20px;
    margin-right: 4px;
    @media (min-width: 40em) {
      width: 28px;
      height: 28px;
      margin-right: 6px;
    }
  }
`

export const ButtonNext = styled(NavButton)`
  position: absolute;
  right: -11px;
  z-index: 99;
  top: calc(50% - 3rem);

  @media (min-width: 40em) {
    right: -17px;
    top: calc(50% - 4.5rem);
  }

  svg {
    filter: brightness(0%);
    transform: rotate(-90deg);
    width: 20px;
    margin-left: 4px;
    @media (min-width: 40em) {
      width: 28px;
      height: 28px;
      margin-left: 6px;
    }
  }
`
