import styled from "styled-components"

const ContentWrapper = styled.section`
  height: fit-content;
  z-index: 1;
  scroll-margin-top: 6rem;
  background-color: #fff;
  box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px,
    2px 4px 10px rgba(0, 0, 0, 0.15);

  max-width: calc(100% - 2.5rem);
  grid-area: applybox;
  padding: 2rem;
  margin-top: -24rem;

  @media (min-width: 40em) {
    margin-top: -52%;
    padding: 3rem;
  }
  @media (min-width: 64em) {
    margin-top: -37rem;
  }
`

export default ContentWrapper
