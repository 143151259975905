import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.79rem;
  margin-bottom: 2.67rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (min-width: 40em) {
    margin-top: 7.56rem;
    margin-bottom: 3.67rem;
  }
`

const Title = styled.label`
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 2.0625em;
  line-height: 54px;

  margin-top: 2rem;
  color: #1008bd;

  @media (min-width: 82em) {
    max-width: 30ch;
  }
`

const Text = styled.label`
  font-family: var(--ff-primary);
  font-size: 1em;
  font-weight: 400;
  line-height: 23px;
  max-width: 20.25rem;
  color: #0f0f0f;
`

const ErrorMessage = () => {
  return (
    <ModalContent role="alert">
      <StaticImage
        src="../../../assets/images/unhappy_face.png"
        alt="unhappy face"
        placeholder="tracedSVG"
      />
      <Title>Something went wrong!</Title>
      <Text>
        We are having some temporary disturbance.
        <br />
        <br />
        Please try again later.
      </Text>
    </ModalContent>
  )
}

export default ErrorMessage
