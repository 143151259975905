import * as React from "react"
import styled from "styled-components"
import ContactForm from "../form/ContactForm"
import BackgroundImage from "./BackgroundImage"
import ContentWrapper from "./ContentWrapper"
import FormBoxContainer from "./FormBoxContainer"
import { PrimaryTitle } from "./Titles"
import ContactInfoBox from "./ContactInfoBox"

const ApplyBoxTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 40em) {
    align-items: start;
  }
  @media (min-width: 64em) {
    align-items: center;
  }
`

const StyledPrimaryTitle = styled(PrimaryTitle)``

const ContactBox = ({ prefilled }) => {
  return (
    <>
      <BackgroundImage />
      <FormBoxContainer>
        <ContentWrapper id="contact">
          <ApplyBoxTitles>
            <StyledPrimaryTitle>
              Want to start a project with us?
            </StyledPrimaryTitle>
            <ContactInfoBox
              phone="+358503660186"
              name="Max Niskanen"
              email="max.niskanen@rebase.fi"
              linkedIn="https://www.linkedin.com/in/max-niskanen-b4011771/"
            />
          </ApplyBoxTitles>
          <ContactForm prefilled={prefilled} />
        </ContentWrapper>
      </FormBoxContainer>
    </>
  )
}

export default ContactBox
