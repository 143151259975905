import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.79rem;
  margin-bottom: 2.67rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (min-width: 40em) {
    margin-top: 7.56rem;
    margin-bottom: 2.67rem;
  }

  @media (max-height: 680px) {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
`

const Title = styled.label`
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 2.125em;

  color: #1008bd;
  margin-top: 2rem;

  @media (min-width: 62em) {
    font-size: 3em;
  }

  @media (min-width: 82em) {
    max-width: 30ch;
  }

  @media (max-height: 680px) {
    font-size: 1.5em;
  }
`

const Text = styled.label`
  font-family: var(--ff-primary);
  font-size: 1em;
  font-weight: 400;
  line-height: 23px;
  max-width: 20.25rem;
  color: #0f0f0f;
  margin-top: 1.25rem;

  @media (max-height: 680px) {
    font-size: 0.875em;
  }
`

const SuccessMessage = ({ services }) => {
  return (
    <ModalContent role="alert">
      <StaticImage
        src="../../../assets/images/check_circle.png"
        alt="check circle"
        placeholder="tracedSVG"
      />
      <Title>
        {services ? "Thank you for your interest!" : "Thank you for applying!"}
      </Title>
      <Text>
        {services
          ? "We've received your inquiry and will be in touch shortly. We look forward to assisting you and providing solutions tailored to your needs."
          : "We´ll review your application and contact you for an interview within two weeks if your skills are a match."}
      </Text>
    </ModalContent>
  )
}

export default SuccessMessage
