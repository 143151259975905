import React from "react"
import styled from "styled-components"
import Chevron from "../../assets/svgs/chevron.svg"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "../../styles/carousel/carousel.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import StoryBlock from "./StoryBlock"

const StoryCarouselContainer = styled.div`
  margin-inline: auto;
  width: 100%;
  margin-top: -1.625rem !important;
  @media (min-width: 40em) {
    width: min(1166px, 100% - 4rem);
    margin-top: -1.3rem !important;
    margin-bottom: -1rem !important;
  }
  @media (min-width: 64em) {
  }
`

const NavLink = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: var(--clr-text-blue);
  user-select: none;
`

const ChevronLeft = styled.div`
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;

    @media (min-width: 40em) {
      height: 35px;
      width: 35px;
    }
    @media (min-width: 64em) {
      height: 45px;
      width: 45px;
    }
  }

  margin-right: 0.8rem;
  transform: rotate(90deg);
`

const ChevronRight = styled.div`
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;

    @media (min-width: 40em) {
      height: 35px;
      width: 35px;
    }
    @media (min-width: 64em) {
      height: 45px;
      width: 45px;
    }
  }
  margin-left: 0.8rem;
  transform: rotate(-90deg);
`

const SwiperWrapper = styled.div`
  position: relative;
  display: none;
  @media (min-width: 40em) {
    display: flex;
  }
`

const StyledSwiper = styled(Swiper)`
  padding-top: 1.3rem !important;
  padding-bottom: 1rem !important;
  padding-right: 9px !important;
  padding-left: 9px !important;
  width: calc(100% + 18px) !important;
  margin-left: -9px !important;

  .swiper-wrapper {
    margin-right: -24px;
  }

  .swiper-slide {
    max-width: 584px;
    text-align: left;
  }

  @media (min-width: 40em) {
    width: 100% !important;
    margin-left: 0 !important;
  }
`

const MobileCarousel = styled.div`
  text-align: left;
  @media (min-width: 40em) {
    display: none;
  }
`

const StoryCarousel = ({ stories, thisStory = undefined }) => {
  const [swiper, setSwiper] = React.useState()

  const otherStories = [...stories]

  console.log(thisStory)

  if (thisStory !== undefined) {
    otherStories.splice(thisStory, 1)
    console.log("splicing")
  } else {
    thisStory = 1
  }
  console.log(stories)
  console.log(otherStories)

  React.useEffect(() => {
    if (swiper) {
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  return (
    <StoryCarouselContainer>
      <SwiperWrapper>
        <NavLink className="button-previous">
          <ChevronLeft>
            <Chevron />
          </ChevronLeft>
        </NavLink>
        <StyledSwiper
          slidesPerView={1}
          navigation={{
            prevEl: ".button-previous",
            nextEl: ".button-next",
          }}
          spaceBetween={24}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
          }}
          modules={[Navigation]}
          onSwiper={setSwiper}
          initialSlide={thisStory - 1}
          loop={true}
        >
          {otherStories.map(story => (
            <SwiperSlide key={story.id}>
              <StoryBlock story={story.frontmatter} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <NavLink className="button-next">
          <ChevronRight>
            <Chevron />
          </ChevronRight>
        </NavLink>
      </SwiperWrapper>

      <MobileCarousel>
        <Swiper
          pagination={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          breakpoints={{
            385: {
              spaceBetween: 16,
            },
          }}
          loop={true}
          className="mySwiper"
          modules={[Pagination]}
        >
          {otherStories.map(story => (
            <SwiperSlide className="swiper-slide-mobile" key={story.id}>
              <StoryBlock story={story.frontmatter} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileCarousel>
    </StoryCarouselContainer>
  )
}

export default StoryCarousel
