import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import CertificationTextBottom from "../common/CertificationTextBottom"

const AboutSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5.5rem;
  margin-inline: auto;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 6.75rem;
  background: var(--clr-bg-grey);

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media (min-width: 40em) {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    text-align: center;
  }
`

const AboutParagraph = styled.p`
  color: var(--clr-text-blue);
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  letter-spacing: 0.0015em;
  height: fit-content;
  text-align: left;

  @media (min-width: 40em) {
    font-size: 1.5em;
    line-height: 36px;
    max-width: 28.125rem;
  }
`

const AboutParagraphMobile = styled(AboutParagraph)`
  @media (min-width: 40em) {
    display: none;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  justify-content: space-between;
  gap: 3rem;
  @media (min-width: 50em) {
    gap: 5.5rem;
  }
  @media (min-width: 40em) {
    flex-direction: row;
  }

  .image {
    margin-bottom: auto;
    @media (min-width: 40em) {
      min-width: 300px;
      width: 450px;
    }
    @media (min-width: 50em) {
      min-width: 320px;
      width: 450px;
    }
  }
`

const SectionTop = styled(Section)`
  display: none;
  @media (min-width: 40em) {
    display: flex;
  }
`

const AboutSection = () => {
  return (
    <AboutSectionContainer>
      <SectionTop>
        <AboutParagraph>
          Rebase was established in 2020 to bring together talent in software,
          cloud, design, and agile business by building a workplace around
          freedom and simplicity. Together we want to help take our clients
          further, enabling growth and success for all.
        </AboutParagraph>
        <StaticImage
          className="image"
          src="../../images/landing-page/front_02.jpg"
          alt=""
        />
      </SectionTop>
      <Section>
        <CertificationTextBottom />
        <AboutParagraphMobile>
          Rebase was established in 2020 to bring together talent in software,
          cloud, design, and agile business by building a workplace around
          freedom and simplicity. Together we want to help take our clients
          further, enabling growth and success for all.
        </AboutParagraphMobile>
        <AboutParagraph>
          Rebase is a market-driven organisation committed to inclusivity and
          diversity, we aim to create profitable and sustainable growth.
          Believing in teamwork and empowering environments, Rebase is an
          independent company that is also a part of the Gofore Plc ecosystem.
        </AboutParagraph>
      </Section>
    </AboutSectionContainer>
  )
}

export default AboutSection
