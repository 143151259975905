import React from "react"
import styled from "styled-components"
import SecondaryTitle from "../common/SecondaryTitle"
import StoryCarousel from "../stories/StoryCarousel"
import { DiscoverLink } from "./offerboxes/DiscoverLink"

const StoriesContainer = styled.section`
  margin-bottom: 5.5rem;
  @media (min-width: 40em) {
    margin-bottom: 7.5rem;
  }
`

const StyledDiscoverLink = styled(DiscoverLink)`
  margin-inline: auto;
  margin-top: 1rem;
  @media (min-width: 40em) {
    margin-top: 3rem;
  }
`

const StoriesSection = ({ data }) => {
  console.log(data.story.nodes)
  return (
    <StoriesContainer>
      <SecondaryTitle>Our Stories and Insights</SecondaryTitle>
      <StoryCarousel stories={data.story.nodes} />
      <StyledDiscoverLink to="/stories" color={"var(--clr-text-blue)"}>
        Read more of our stories
        <svg
          className="arrow-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
        >
          <g
            fill="none"
            stroke="#150F9D"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
          >
            <circle
              className="arrow-icon--circle"
              cx="17"
              cy="17"
              r="16.12"
            ></circle>
            <path
              stroke="none"
              className="arrow-icon--arrow"
              d="M14.7949 6.5L12.3274 8.9675L20.3424 17L12.3274 25.0325L14.7949 27.5L25.2949 17L14.7949 6.5Z"
              fill="#150F9D"
            ></path>
          </g>
        </svg>{" "}
        <br />
      </StyledDiscoverLink>
    </StoriesContainer>
  )
}

export default StoriesSection
